import type { NextPage } from 'next';
import Head from 'next/head';

import { PageError } from '@components/page';
import { defaultTheme } from '@constants/theme';
import { ThemeContext } from '@context/theme';
import { StatusCodes } from '@enums/status-codes';

const NotFound: NextPage = () => {
  return (
    <ThemeContext.Provider value={{ isLight: true, theme: defaultTheme, setTheme: () => {} }}>
      <Head>
        <title>404 Not Found</title>
        <link rel="icon" href={defaultTheme.image.faviconHref} />
      </Head>
      <PageError statusCode={StatusCodes.NotFound} />
    </ThemeContext.Provider>
  );
};

export default NotFound;
